<template>
    <path id="Vector (Stroke)_49" fill-rule="evenodd" clip-rule="evenodd"
        d="M458.519 309H550.031L584.547 378.033L550.031 447.065H458.513L424 377.891L458.519 309ZM465.232 319.871L436.154 377.902L465.238 436.194H543.312L572.393 378.033L543.312 319.871H465.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_50" fill-rule="evenodd" clip-rule="evenodd"
        d="M458.519 160H550.031L584.547 229.033L550.031 298.065H458.513L424 228.891L458.519 160ZM465.232 170.871L436.154 228.902L465.238 287.194H543.312L572.393 229.033L543.312 170.871H465.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_51" fill-rule="evenodd" clip-rule="evenodd"
        d="M458.519 11H550.031L584.547 80.0326L550.031 149.065H458.513L424 79.8915L458.519 11ZM465.232 21.8713L436.154 79.9018L465.238 138.194H543.312L572.393 80.0325L543.312 21.8713H465.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_52" fill-rule="evenodd" clip-rule="evenodd"
        d="M458.519 458H550.031L584.547 527.033L550.031 596.065H458.513L424 526.891L458.519 458ZM465.232 468.871L436.154 526.902L465.238 585.194H543.312L572.393 527.033L543.312 468.871H465.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_53" fill-rule="evenodd" clip-rule="evenodd"
        d="M458.519 607H550.031L584.547 676.033L550.031 745.065H458.513L424 675.891L458.519 607ZM465.232 617.871L436.154 675.902L465.238 734.194H543.312L572.393 676.033L543.312 617.871H465.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_54" fill-rule="evenodd" clip-rule="evenodd"
        d="M458.519 756H550.031L584.547 825.033L550.031 894.065H458.513L424 824.891L458.519 756ZM465.232 766.871L436.154 824.902L465.238 883.194H543.312L572.393 825.033L543.312 766.871H465.232Z"
        fill="#E8D9AA" />
</template>